import React, {useState} from "react";
import { PageMeta } from "../../Api/pagedata";
import {Helmet} from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import "./blog.css";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import { FaUser, FaClock } from "react-icons/fa6";

export default function Blog(){
    const [PageMetas, setPageMeta] = useState({});
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const secondSegment = pathSegments[0] || '';
    return(
        <React.Fragment>
            <PageMeta currentpageurl={secondSegment} onFetch={setPageMeta} />
            <Helmet>
                <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Blog | Dance Embassy'}</title>
                <meta name="description" content={PageMetas.metadescription || ''} />
                <meta name="keywords" content={PageMetas.metakeyword || ''} />
            </Helmet>
            <Navbar/>
                <section className="breadcrumbarea">
                    <div className="container">
                        <div className="breadcrumhead">
                            <h1>Blog</h1>
                        </div>
                    </div>
                </section>
                <section className="blogarea">
                    <div className="container-fluid">
                        <div className="row align-items-center g-0">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                                <Link to="#">
                                    <div className="bloggridimg">
                                        <img src="https://www.danceembassy.com/wp-content/uploads/2022/01/Copy-of-Template-for-Blog.png" alt="" />
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                                <div className="bloggirdcontent">
                                    <div className="blogridheaidng">
                                        <h2><Link to="#">A Glitzy End to 2021</Link></h2>
                                    </div>
                                    <div className="blogridmeta">
                                        <div className="blogauthor"><p><FaUser/><span>Dance Embassy</span></p></div>
                                        <div className="blogdate"><p><FaClock/> <span>January 13th, 2022</span></p></div>
                                    </div>
                                    <div className="blogridshordis">
                                        <p>One of our students casually mentioned that we should have a gathering to conclude 2021. If you know of our Founder, Lavania, you know she doesn’t do anything. She thought it was a brilliant idea and arranged for a fanciful dinner for the Dance Embassy community. It was held at the Singapore Cricket Club. 3 tables of 5 were booked and all were filled almost immediately! I guess the idea of a year end party did tickle everyone’s fancy. The theme was glitz and glam and GIRLLL, did everyone turn</p>
                                    </div>
                                    <div className="blogridreadmore">
                                        <Link to="#">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center g-0">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                                <div className="bloggirdcontent">
                                    <div className="blogridheaidng">
                                        <h2><Link to="#">Reasons For Taking Dance Lessons</Link></h2>
                                    </div>
                                    <div className="blogridmeta">
                                        <div className="blogauthor"><p><FaUser/><span>Dance Embassy</span></p></div>
                                        <div className="blogdate"><p><FaClock/> <span>July 2nd, 2021</span></p></div>
                                    </div>
                                    <div className="blogridshordis">
                                        <p>Put aside your preconceived ideas and get ready to work your hips to burn up the dance floor. Do like the thousands of people who are starting to learn dance! It is a trendy activity, and the number of practitioners is constantly increasing. So to finish convincing you, here are excellent reasons to walk through the door of a</p>
                                    </div>
                                    <div className="blogridreadmore">
                                        <Link to="#">Read More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                                <Link to="#">
                                    <div className="bloggridimg">
                                        <img src="https://www.danceembassy.com/wp-content/uploads/2021/07/Adult-Dance-Classes-singapore.jpg" alt="" />
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="row align-items-center g-0">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                                <Link to="#">
                                    <div className="bloggridimg">
                                        <img src="https://www.danceembassy.com/wp-content/uploads/2021/05/Copy-of-Template-for-Blog.png" alt="" />
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                                <div className="bloggirdcontent">
                                    <div className="blogridheaidng">
                                        <h2><Link to="#">I nearly went to Jail for DE</Link></h2>
                                    </div>
                                    <div className="blogridmeta">
                                        <div className="blogauthor"><p><FaUser/><span>Dance Embassy</span></p></div>
                                        <div className="blogdate"><p><FaClock/> <span>May 31st, 2021</span></p></div>
                                    </div>
                                    <div className="blogridshordis">
                                        <p>I was starting out and at every opportunity that came my way, I grabbed it. One such opportunity was planning a flashmob in Little India for a tour company. Excited, I headed down to Little India to a brightly coloured compound and spoke to the vendors there. They gave me the green light to do it in front of their shops. After all, it was a win-win situation. One of the vendors told me to head up a staircase to ask for clearance from their boss. “But, isn’t it public property?” I asked and he said rather fearfully that nothing can be done until an approval from the boss is given. </p>
                                    </div>
                                    <div className="blogridreadmore">
                                        <Link to="#">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer/>
        </React.Fragment>
    )
}