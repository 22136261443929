import React, {useState} from "react";
import { PageMeta } from "../../../Api/pagedata";
import {Helmet} from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import "./widding.css";
import Navbar from "../../../Components/Navbar/navbar";
import Footer from "../../../Components/Footer/footer";
import Findus from "../../../Components/Findus/findus";
import Clietns from "../../../Components/Ourclient/ourclient";
import { Modal, Button } from 'react-bootstrap';
import YouTube from 'react-youtube';
import { ReactComponent as Youtubeicon } from "../../../assets/images/youtubevideo.svg";

export default function Kidsdance(){
    const [isOpen, setIsOpen] = useState(false);
    const [videoId, setVideoId] = useState('');
    const handleClose = () => setIsOpen(false);
    const openModal = (id) => {
        setVideoId(id);
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
        setVideoId('');
    };
    const opts = {
        height: '500px',
        width: '100%',
        playerVars: {
            autoplay: 1, 
            rel: 0, 
        },
    };
    const [PageMetas, setPageMeta] = useState({});
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const secondSegment = pathSegments[0] || '';
    return(
        <React.Fragment>
            <PageMeta currentpageurl={secondSegment} onFetch={setPageMeta} />
            <Helmet>
                <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Wedding Dance | Dance Embassy'}</title>
                <meta name="description" content={PageMetas.metadescription || ''} />
                <meta name="keywords" content={PageMetas.metakeyword || ''} />
            </Helmet>
            <Navbar/>
                <section className="breadcrumbarea">
                    <div className="container">
                        <div className="breadcrumhead">
                            <h1>Wedding Dance</h1>
                        </div>
                    </div>
                </section>
                <section className="kidsdance weddingdance">
                    <div className="container-fluid">
                        <div className="row g-0 align-items-center">
                            <div className="col-lg-6">
                                <div className="kidsvideo">
                                    <div className="getsocialbox">
                                        <img src="https://img.youtube.com/vi/LsB_wX_2ymY/0.jpg" alt="YouTube Thumbnail" onClick={() => openModal('LsB_wX_2ymY')} style={{ cursor: 'pointer' }} />
                                        <div className="videobtns" onClick={() => openModal('LsB_wX_2ymY')}><Youtubeicon/></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="kidescontent">
                                    <p>We pride ourselves on being the most sought-after Wedding Choreography company. From hosting Bachelorettes party and sangeets to reassuring 2 left feet family members, we will be there with you at every step.</p>
                                    <p>Tailoring to your needs and themes, we aspire to ensure that you wedding day will be the best day of your life.</p>
                                    <p>We also provide destination wedding choreography services and performances. We have travelled to Bangkok, Bali, Malaysia these years to provide a full range of wedding choreography and performances.</p>
                                    <div className="kidscta">
                                        <Link to="/contact-us/">Contact Us</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Modal show={isOpen} onHide={closeModal} centered size="lg" dialogClassName="video-popup">
                    <Modal.Body>
                        <div className="videopopupclose">
                            <Button variant="close" onClick={handleClose} aria-label="Close"></Button>
                        </div>
                        {videoId && <YouTube videoId={videoId} opts={opts} />}
                    </Modal.Body>
                </Modal>
            <Findus/>
            <Clietns/>
            <Footer/>
        </React.Fragment>
    )
}