import React, {useState} from "react";
import { PageMeta } from "../../Api/pagedata";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import "./home.css";
import celebrateimage from "../../assets/images/imagecele.jpeg";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import Slider from "../../Components/Slider/slider";
import Services from "../../Components/ServicesBox/servicetwo";
import Founder from "../../Components/Founder/founder";
import Review from "../../Components/Reviews/review";
import Clients from "../../Components/Ourclient/ourclient";
import Instagram from "../../Components/Instagram/instagram";
// import Popup from "../../Components/Popup/homepage";
import { BsFillPeopleFill } from "react-icons/bs";
import { ImAccessibility } from "react-icons/im";
import { ImHeart } from "react-icons/im";
import Givingback1 from "../../assets/images/givingback.jpg";
import Givingback2 from "../../assets/images/givingbacklogo.svg";

export default function Home(){
    const [PageMetas, setPageMeta] = useState({});
    return(
        <React.Fragment>
            <PageMeta currentpageurl={'home'} onFetch={setPageMeta} />
            <Helmet>
                <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Home | Dance Embassy'}</title>
                <meta name="description" content={PageMetas.metadescription || ''} />
                <meta name="keywords" content={PageMetas.metakeyword || ''} />
            </Helmet>
            <Navbar/>
            <Slider/>
            <Services/>
            <section className="celebrate celebratecoloumrevert">
                <div className="row align-items-center g-0">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div className="celebratecotnent">
                            <h2>We celebrate 12 years!</h2>
                            <p>These past 12 years have been quite a journey and for those who have been with us since the start, you will attest to it. We have grown in more ways than one and it would not have been possible with the support of our students, instructors and corporate clients.</p>
                            <p>We have achieved global recognition but we are humbled to know that we still have a long way to go.</p>
                            <p>Join us as we celebrate our 12th year. We bring to you exciting events, meetups, giveaways and our FIRST ever dance recital! Look out for all these details in our monthly newsletter.</p>
                            <div className="celebratebtns">
                                <Link to="/contact-us/">Book Now</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div className="celebrateimage">
                            <img src={celebrateimage} alt="celebrate" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="dancing">
                <div className="container">
                    <div className="row g-0">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="dancinggrid danceingone">
                                <div className="dancingiconare"><BsFillPeopleFill/></div>
                                <div className="danceingridcontent">
                                    <h2>Elicit Enthusiasm Within Your Team</h2>
                                    <p>Our tailored workshops/sessions will strengthen your team bond!</p>
                                    <div className="dancegrbtn">
                                        <Link to="/team-building-workshops/">Find out more</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="dancinggrid danceingtwo">
                                <div className="dancingiconare"><ImAccessibility/></div>
                                <div className="danceingridcontent">
                                    <h2>We Dance, We Entertain</h2>
                                    <p>Our team of performers are ready to spice up your event!</p>
                                    <div className="dancegrbtn">
                                        <Link to="/contact-us/">Find out more</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="dancinggrid danceingthree">
                                <div className="dancingiconare"><ImHeart/></div>
                                <div className="danceingridcontent">
                                    <h2>We Love Hearing Wedding Bells</h2>
                                    <p>Trust us to ensure your wedding will be the best day of your life!</p>
                                    <div className="dancegrbtn">
                                        <Link to="/contact-us/">Find out more</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Founder/>
            <Review/>
            <Clients/>
            <section className="givback">
                <div className="row g-0 align-items-center">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div className="givbackimgae">
                            <img src={Givingback1} alt="serive 1"/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div className="givbackcontent">
                            <h2>Giving Back</h2>
                            <p>With the blessings bestowed upon us over the years, we want to bless others and we would love to bring you on this journey with us.</p>
                            <p>We are glad to be supporting our chosen social enterprise, Kriyaid who is commited to making education accessible to kids in rural communities.</p>
                            <p>With each membership, we’ll pledge a portion of it to support their initiatives.</p>
                            <div className="givbackbtn">
                                <Link to="https://kriyaidsg.com/index.php/about-whoweare/" target="_blank">Find out More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="givback givbacktwo">
                <div className="row g-0 align-items-center">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div className="givbackcontent">
                            <h2>Giving Back</h2>
                            <p>Mental health is an important aspect of overall well-being, and at our company, we recognize the importance of supporting initatives surrounding mental health.</p>
                            <p>We understand that juggling life’s responsibilities and commitments can be stressful at times, and we want to ensure that you have access to resources and support that can help you manage your mental health.</p>
                            <p>Therefore, Dance Embassy is a contributing and support partner to Samaritans of Singapore, an organisation that provides emotional support to those in crisis, thinking about suicide or affected by suicide.</p>
                            <div className="givbackbtn">
                                <Link to="https://www.sos.org.sg/" target="_blank">Find out More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div className="givbackimgae">
                            <img src={Givingback2} alt="serive 1"/>
                        </div>
                    </div>
                </div>
            </section>
            <Instagram/>
            <Footer/>
        </React.Fragment>
    )
}