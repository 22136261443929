import React, { useEffect, useState } from 'react';
import { PageMeta } from "../../../Api/pagedata";
import {Helmet} from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import "./classschedule.css";
import Navbar from "../../../Components/Navbar/navbar";
import Footer from "../../../Components/Footer/footer";
import Accordion from 'react-bootstrap/Accordion';
import Clients from "../../../Components/Ourclient/ourclient";

export default function Classchedule() {
    useEffect(() => {
      const script = document.createElement("script");
      script.async = true;
      script.type = "module";
      script.setAttribute("host_id", "9357");
      script.setAttribute("teacher_ids", "[]");
      script.setAttribute("location_ids", "[]");
      script.setAttribute("tag_ids", "[]");
      script.setAttribute("default_filter", "show-all");
      script.src = "https://momence.com/plugin/host-schedule/host-schedule.js";
      const alternativeContainer = document.getElementById("alternative-container");
      if (alternativeContainer) {
        alternativeContainer.appendChild(script);
      }
    }, []);
    const [PageMetas, setPageMeta] = useState({});
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const secondSegment = pathSegments[0] || '';
    return(
        <React.Fragment>
            <PageMeta currentpageurl={secondSegment} onFetch={setPageMeta} />
            <Helmet>
                <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Class Schedule | Dance Embassy'}</title>
                <meta name="description" content={PageMetas.metadescription || ''} />
                <meta name="keywords" content={PageMetas.metakeyword || ''} />
            </Helmet>
            <Navbar />
            <section className="breadcrumbarea">
            <div className="container">
                <div className="breadcrumhead">
                <h1>Class Schedule</h1>
                </div>
            </div>
            </section>
            <section className="momencecode">
                <div id="alternative-container"></div>
            </section>
            <section className="faqs">
                <div className="container">
                    <h3>FAQ</h3>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>How do I book a Single Class?</Accordion.Header>
                            <Accordion.Body>
                                <p>Click <Link to="#">here</Link> to go onto our Booking page, select your preferred slot and make the payment.</p>
                                <p>You will then receive a confirmation email  <span role="img" aria-label="smiling face">🙂</span> Get ready to dance till your feet drop!!!</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>I want to attend more than one class, are there any packages available?</Accordion.Header>
                            <Accordion.Body>
                                <p>There are 2 options for our Class Packages:</p>
                                <p>$150 for 10 Virtual Classes</p>
                                <p>$250 for 10 In-Studio Classes</p>
                                <p>Terms and Conditions for these packages: </p>
                                <ul>
                                    <li>Both packages are valid for 3 months and are non-transferable.</li>
                                    <li>The validity of the package starts on the date of your first class.</li>
                                    <li>The In-Studio class package is valid for all In-Studio classes.</li>
                                    <li>The Virtual class package is valid for all Virtual classes listed on our class schedule.</li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>How do I book a Class if I already have an existing package?</Accordion.Header>
                            <Accordion.Body>
                                <p>Simply click <Link to="#">here</Link> & fill in the necessary details and we will send you the confirmation shortly (:</p>
                                <p>or use our DE App to book your slot (by clicking Class Schedule and choosing the class that you would like to attend)</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>What can I expect in a Virtual DE class?</Accordion.Header>
                            <Accordion.Body>
                                <p>A DE instructor that will eagerly be waiting for you to join, making sure you are all ready for a great session!</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>I am a Beginner, can I still attend a DE class?</Accordion.Header>
                            <Accordion.Body>
                                <p>Yes, of course. Our instructors are all trained to ensure that you are comfortable and will take away something from the session.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>What can I expect in the signature BollyFreak class?</Accordion.Header>
                            <Accordion.Body>
                                <p>You can expect high energy dance moves to Bollywood hits and packing in a good sweat session.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>What attire/tools do I need for a class?</Accordion.Header>
                            <Accordion.Body>
                                <p>For every class, wear your most comfortable clothes or gym wear to move in. For our BollyFreak and meditation classes, you will need a yoga mat too.</p>
                                <p>Oh and water of course! Hydrate, hydrate, Hydrate…</p>
                                <p>For our virtual classes, you would need a Computer & a safe space for you to move around!</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>How does a Hybrid class work?</Accordion.Header>
                            <Accordion.Body>
                                <p>A hybrid class is referred to as a class that will be streamed live on Zoom from an In-studio class.</p>
                                <p>You can be rested assured that there will be an equal focus on both Virtual & In-Studio participants during these classes.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>What happens if I can't make it for a class that I have booked?</Accordion.Header>
                            <Accordion.Body>
                                <p>Cancellations will have to be done 24 hours before or there will be no refunds.</p>
                                <p>Please email <Link to="mailto:lavania@danceembassy.com">lavania@danceembassy.com</Link> to cancel your booking.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </section>
            <Clients/>
            <Footer />
        </React.Fragment>
    );
  }