import React, { useState } from "react";
import InstagramFeed from "../../Api/instagramfeed";
import "./instagram.css";
import FevIcon from "../../assets/images/fev.jpg";
import { Link } from "react-router-dom";
import {WebsiteSettings} from "../../Api/pagedata";

export default function Instagram(){
    const [Instafeed, setInstagram] = useState([]);
    const [Websitedata, setWebsiteSetting] = useState([]);
    return(
        <>
        <InstagramFeed onFetch={setInstagram} />
        <WebsiteSettings onFetch={setWebsiteSetting} />
        <section className="instagram">
            <div className="container-fluid">
                <div className="w90">
                    <div className="instaheading">
                        <h2>Follow Us On Instagram</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid p0">
                <div className="instahead">
                    <div className="instaimgtag">
                        <div className="instaimglogo">
                            <img src={FevIcon} alt="Instagram Logo" />
                        </div>
                        <div className="instanametag">
                            <h2><a href={Websitedata.instagram_url} target="_blank"rel="noopener noreferrer">Dance Embassy</a></h2>
                            <p><a href={Websitedata.instagram_url} target="_blank"rel="noopener noreferrer">@danceembassy</a></p>
                        </div>
                    </div>
                    <div className="instatotalpost">
                        <h2>{Instafeed?.totalpost?.toLocaleString() || '0'}</h2>
                        <p>Posts</p>
                    </div>
                    <div className="instatotalpost">
                        <h2>1,215</h2>
                        <p>Followers</p>
                    </div>
                    <div className="instatotalpost">
                        <h2>182</h2>
                        <p>Following</p>
                    </div>
                    <div className="instafollowbtn">
                        <a href={Websitedata.instagram_url} target="_blank"rel="noopener noreferrer">Follow</a>
                    </div>
                </div>
                <div className="instapost">
                    <div className="instarow">
                        {Instafeed.instagram && Instafeed.instagram.length > 0 ? (
                            Instafeed.instagram.map((Instafeeds, index) => 
                                Instafeeds && (
                                    <div className="colom-5" key={index}>
                                        <div className="instagbox">
                                            <Link to={Instafeeds.permalink} target="_blank"rel="noopener noreferrer">
                                                <img src={Instafeeds.thumbnail_url} alt="Insta post"/>
                                                <div className="istafeed">
                                                    <p>{Instafeeds.caption}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                )
                            )
                        ) : (
                            <p className="text-center">No Instagram posts available.</p>
                        )}
                    </div>
                </div>
            </div>   
        </section>
        </>
    )
}