import React, {useState} from "react";
import { PageMeta } from "../../Api/pagedata";
import {Helmet} from "react-helmet";
import "./joinus.css";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import { Link, useLocation } from "react-router-dom";
import Joinusimg from "../../assets/images/joinus.jpg"

export default function Joinus() {
    const [PageMetas, setPageMeta] = useState({});
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const secondSegment = pathSegments[0] || '';
    return(
        <React.Fragment>
            <PageMeta currentpageurl={secondSegment} onFetch={setPageMeta} />
            <Helmet>
                <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Join us | Dance Embassy'}</title>
                <meta name="description" content={PageMetas.metadescription || ''} />
                <meta name="keywords" content={PageMetas.metakeyword || ''} />
            </Helmet>
            <Navbar />
            <section className="breadcrumbarea">
                <div className="container">
                    <div className="breadcrumhead">
                        <h1>Join us</h1>
                    </div>
                </div>
            </section>
            <section className="joinus">
                <div className="container-fluid">
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-6">
                            <div className="joinusimg">
                                <img src={Joinusimg} alt="Join us" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="joinuscontent">
                                <h2>Realise Your Dreams of transforming lives with us.</h2>
                                <p>Welcome to our Community of Dance, Fitness & Wellness Lovers.</p>
                                <p>Our Ambassadors are Stellar Professionals in their field of expertise.</p>
                                <p>Join our DE Family today!</p>
                                <div className="joinusbtn">
                                    <Link to="#">Sign up now!</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ourfeatures">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}
