import React, {useState} from "react";
import { PageMeta } from "../../Api/pagedata";
import {Helmet} from "react-helmet";
import "./faqs.css";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import { Link, useLocation } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';

export default function Faq() {
    const [PageMetas, setPageMeta] = useState({});
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const secondSegment = pathSegments[0] || '';
    return(
        <React.Fragment>
            <PageMeta currentpageurl={secondSegment} onFetch={setPageMeta} />
            <Helmet>
                <title>{PageMetas.metatitle ? PageMetas.metatitle : 'FAQ | Dance Embassy'}</title>
                <meta name="description" content={PageMetas.metadescription || ''} />
                <meta name="keywords" content={PageMetas.metakeyword || ''} />
            </Helmet>
            <Navbar />
            <section className="breadcrumbarea">
                <div className="container">
                    <div className="breadcrumhead">
                        <h1>Frequently Asked Questions</h1>
                        <p>We’ve put together some commonly asked questions to give you more information about DE and our services.</p>
                    </div>
                </div>
            </section>
            <section className="faqsarea">
                <div className="container-fluid">
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-6">
                            <div className="faqsdata">
                                <h3>Ambassadors</h3>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>What does it mean to be a DE Ambassador?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>As a DE Ambassador, you will have access to a wealth of resources and support that you can use to position yourself as a professional in your field.</p>
                                            <p>In a sea of experts, DE will support you in your endeavours to stand out from the rest.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>How can I sign up as a DE Ambassador?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>(Simply 1) sign up through the <Link to="#">Join Us!</Link> page</p>
                                            <p>(2) Select a plan</p>
                                            <p>(3) Fill in the <Link to="#">google form</Link> and</p>
                                            <p>(4) A DE consultant will get on a call with you to help you with getting on board.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>How much is it to become a DE Ambassador?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>We have two DE Ambassador plans, a Standard plan of S$89 & a Premium plan of S$149.</p>
                                            <p>To learn more about these plans, please check out the <Link to="#">Join Us!</Link> page.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Is there is a minimum time frame required for being a DE Ambassador?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>We recommend a minimum of 6 months, in order for us to successfully help you build your Brand.</p>
                                            <p>However, there is no minimum requirement for the Sign up.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>Do Ambassadors get featured across DE social media platforms?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Yes! Of course!</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>Do I have to be in Singapore to be eligible for sign up?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Anyone around the world can sign up as an Ambassador with us!</p>
                                            <p>The more the merrier!</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="faqsarea corportate">
                <div className="container-fluid">
                    <div className="row g-0 align-items-center justify-content-end">
                        <div className="col-lg-6">
                            <div className="faqsdata">
                                <h3>Corporate Classes</h3>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>How do I book DE for a Corporate session?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Kindly fill up the <Link to="#">contact form</Link> with the subject: “Free demo for Corporate”.</p>
                                            <p>A DE consultant will then reach out to discuss further. During this call, we aim to understand your needs and thus enabling us to ensure your session is a success.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>What can I expect in a DE Team Building session?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Curation of the workshop/session is dependant on the expectations and goals of what you would like to achieve.</p>
                                            <p>We will recommend a few ideas for you to explore and ultimately you can decide what will be best suited for you and your team.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>I want to book more than one session, are there any packages available?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Of course, this can be discussed with our DE consultant.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Do you hold virtual team building workshops?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Yes, we do! We can advise you on the variety of engaging workshops we are able to offer.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>Are your team building workshops held in Singapore?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Yes, we do host most of our workshops in Singapore. However, we can plan Destination Workshops as well as per your requirements.</p>
                                            <p>(Due to COVID-19 this service is currently unavailable)</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>What are your top recommended workshops?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Our highly popular workshops are Bollywood dancing, followed by our African dance and meditational workshops.</p>
                                            <p>Check out our variety of classes <Link to="#">here.</Link></p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>Do you offer venues for your team building workshops?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>We can facilitate these workshops at a venue of your choice, as well as make suggestions for potential venues according to your needs.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="faqsarea private">
                <div className="container-fluid">
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-6">
                            <div className="faqsdata">
                                <h3>Private Bookings</h3>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>How do I book a Private Class?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Kindly fill up the <Link to="#">contact form</Link> with the subject: “Interested in a Private classes”.</p>
                                            <p>A DE consultant will then reach out to discuss further.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>What can I expect in a Private class?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Depending on your requirements, we will assign you a suitable and experienced instructor.</p>
                                            <p>They will then get in touch with you to follow through regarding the sessions.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>I want to book more than one session, are there any packages available?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Yes of course. This can be discussed with our DE consultant.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="faqsarea virtual">
                <div className="container-fluid">
                    <div className="row g-0 align-items-center justify-content-end">
                        <div className="col-lg-6">
                            <div className="faqsdata">
                                <h3>Our Virtual/Hybrid Classes</h3>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>How do I book a Single Class?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Click <Link to="#">here</Link> to go onto our Booking page, select your preferred slot and make the payment.</p>
                                            <p>You will then receive a confirmation email <span role="img" aria-label="smiling face">🙂</span> Get ready to dance till your feet drop!!!</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>How do I book a Class if I already have an existing package?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Simply click <Link to="#">here</Link> & fill in the necessary details and we will send you the confirmation shortly (:</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>How do I buy a Class Package?</Accordion.Header>
                                        <Accordion.Body>
                                            <p><b>There are 2 options for our Class Packages:</b></p>
                                            <p>$150 for 10 Virtual Classes</p>
                                            <p>$250 for 10 In-Studio Classes</p>
                                            <p><b>Terms and Conditions for these packages:</b></p>
                                            <ul>
                                                <li>Both packages are valid for 3 months and are non-transferable.</li>
                                                <li>The validity of the package starts on the date of your first class.</li>
                                                <li>The In-Studio class package is valid for all In-Studio classes.</li>
                                                <li>The Virtual class package is valid for all Virtual classes listed on our class schedule.</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>What can I expect in a Virtual DE class?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>A DE instructor that will eagerly be waiting for you to join, making sure you are all ready for a great session!</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>I am a Beginner, can I still attend a DE class?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Yes, of course. Our instructors are all trained to ensure that you are comfortable and will take away something from the session.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>What can I expect in the signature BollyFreak class?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>You can expect high energy dance moves to Bollywood hits and packing in a good sweat session.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>What attire/tools do I need for a class?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>For every class, wear your most comfortable clothes or gym wear to move in. For our BollyFreak and meditation classes, you will need a yoga mat too.</p>
                                            <p>Oh and water of course! Hydrate, hydrate, Hydrate…</p>
                                            <p>For our virtual classes, you would need a Computer & a safe space for you to move around!</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="7">
                                        <Accordion.Header>How does a Hybrid class work?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>A hybrid class is referred to as a class that will be streamed live on Zoom from an In-studio class.</p>
                                            <p>You can be rested assured that there will be an equal focus on both Virtual & In-Studio participants during these classes.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="8">
                                        <Accordion.Header>I want to attend more than one class, are there any packages available?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Our packages include:</p>
                                            <p>S$15/Class or S$120/10 Classes for Virtual Classes</p>
                                            <p>S$28/Class or S$200/10 Classes for In-Studio Classes</p>
                                            <p>They can be purchased via our <Link to="https://danceembassy.reportcard.site/course/6596" target='_blnak'>booking platform</Link>. Simply click on any class and there will be an option for purchasing a package (Virtual or In-Studio) depending on the class.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="8">
                                        <Accordion.Header>What happens if I can't make it for a class that I have booked?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Cancellations will have to be done 24 hours before or there will be no refunds.</p>
                                            <p>Please email <Link to="mailto:lavania@www.danceembassy.com">lavania@www.danceembassy.com</Link> to cancel your booking.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}
