import React, {useState} from "react";
import { PageMeta } from "../../Api/pagedata";
import {Helmet} from "react-helmet";
import "./thankyou.css";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import { Link, useLocation } from "react-router-dom";
import errorimg from "../../assets/images/404-background-img.jpg"

export default function Faq() {
    const [PageMetas, setPageMeta] = useState({});
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const secondSegment = pathSegments[0] || '';
    return(
        <React.Fragment>
            <PageMeta currentpageurl={secondSegment} onFetch={setPageMeta} />
            <Helmet>
                <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Thank You | Dance Embassy'}</title>
                <meta name="description" content={PageMetas.metadescription || ''} />
                <meta name="keywords" content={PageMetas.metakeyword || ''} />
            </Helmet>
            <Navbar />
            <section className="thankyou" style={{ backgroundImage: `url(${errorimg})` }}>
                <div className="container">
                    <div className="row justify-content-end align-items-center">
                        <div className="col-lg-4">
                            <div className="thankyoumeesage">
                                <h2>Thank You!</h2>
                                <p>For contacting us, we will aim to respond to your enquiry within 48hrs.</p>
                                <div className="backtohomebtnsth">
                                    <Link to="/">Back to home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}
