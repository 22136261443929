import React from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import "./notfound.css";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import errorimg from "../../assets/images/404-background-img.jpg"

export default function NotFound(){
    return(
        <React.Fragment>
            <Helmet>
                <title>Error | Dance Embassy</title>
            </Helmet>
            <Navbar/>
                <section className="errorpage" style={{ backgroundImage: `url(${errorimg})` }}>
                    <div className="container">
                        <div className="errorcontent">
                            <h2>404</h2>
                            <h3>Page not found</h3>
                            <p>Oops! The page you are looking for does not exist. It might have been moved or deleted.</p>
                            <div className="errorbtns">
                                <Link to="/">Back To Home</Link>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer/>
        </React.Fragment>
    )
}