import React from "react";
import './founder.css';
import { Link } from "react-router-dom";
import founderimage from "../../assets/images/lav.jpeg";

export default function Founder() {
    return (
        <React.Fragment>
        <section className="ourfounder">
            <div className="row align-items-center g-0">
                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                    <div className="founderimage">
                        <img src={founderimage} alt="Lavania"/>
                    </div>
                </div>
                <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12 col-12">
                    <div className="foundercotnet">
                        <h2>A word from our Founder, Lavania Rosie</h2>
                        <p>Hi! I’m Lavania (Rosie is my Christian name christened by my beloved grandma). When I found Dance Embassy in 2012, little did I expect it to be a global brand with presence in countries like Paris, Malaysia and India.</p>
                        <p>Dance Embassy has been with me in all stages of my life. When I was single, when I was going through a divorce, when I was blessed with a second change, when I had my first kid, when I had my first miscarriage, when I had my second kid…you get the drift? And through it all, I thrived along with it. How? It’s because of the strong support of my students, instructors and clients.</p>
                        <p>Dance Embassy is family and I would love for you to be a part of it.</p>
                        <div className="founderbtns">
                            <Link to="/contact-us/">Join Us!</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </React.Fragment>
    );
}