import React, {useState} from "react";
import { PageMeta } from "../../../Api/pagedata";
import {Helmet} from "react-helmet";
import Navbar from '../../../Components/Navbar/navbar';
import { Link, useLocation } from 'react-router-dom';
import "./location.css";
import Footer from "../../../Components/Footer/footer";
import { FaLocationDot, FaRegClock } from "react-icons/fa6";

export default function Locations() {
    const [PageMetas, setPageMeta] = useState({});
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const secondSegment = pathSegments[0] || '';
    return(
        <React.Fragment>
            <PageMeta currentpageurl={secondSegment} onFetch={setPageMeta} />
            <Helmet>
                <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Studio Locations | Dance Embassy'}</title>
                <meta name="description" content={PageMetas.metadescription || ''} />
                <meta name="keywords" content={PageMetas.metakeyword || ''} />
            </Helmet>
            <Navbar />
            <section className="breadcrumbarea">
                <div className="container">
                <div className="breadcrumhead">
                    <h1>Studio Locations</h1>
                </div>
                </div>
            </section>
            <section className="locations">
                <div className="container-fluid">
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-6">
                            <div className="studiomap">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15955.146040246222!2d103.83498436052474!3d1.3030778289526341!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1963c5b08159%3A0x4bb62c98e703affc!2sDance%20Embassy!5e0!3m2!1sen!2sin!4v1725648275759!5m2!1sen!2sin" width="100%" height="450" title='Dance Embassy' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="studioaddress">
                                <div className="mainaddresshead">
                                    <h2>BollyFreak @ Phil’s Studio</h2>
                                    <h3>Phil’s Studio</h3>
                                </div>
                                <div className="addresslocation">
                                    <p><FaLocationDot/> 1 North Bridge Rd, #03-16 High Street Centre, Singapore 179094</p>
                                    <p><FaRegClock/> Every Monday @ 6.30pm</p>
                                </div>
                                <div className="addressbtns">
                                    <Link to="#">More Info</Link>
                                    <Link to="https://momence.com/u/danceembassy" target='_blank'>Book a Class</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="locations themecolor">
                <div className="container-fluid">
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-6">
                            <div className="studioaddress">
                                <div className="mainaddresshead">
                                    <h2>BollyChoreo @ Balmoral Plaza</h2>
                                    <h3>Happi Dance Studio</h3>
                                </div>
                                <div className="addresslocation">
                                    <p><FaLocationDot/> 271 Bukit Timah Road, Balmoral Plaza #02-15, Singapore 259708</p>
                                    <p><FaRegClock/> Every Monday @ 7pm</p>
                                </div>
                                <div className="addressbtns">
                                    <Link to="#">More Info</Link>
                                    <Link to="https://momence.com/u/danceembassy" target='_blank'>Book a Class</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="studiomap">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15955.062841999306!2d103.81676368715817!3d1.316146800000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19ff56bee347%3A0xdb869a9b11791107!2sDance%20Embassy!5e0!3m2!1sen!2sin!4v1725649863977!5m2!1sen!2sin" width="100%" height="450" title='Dance Embassy' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="locations yellowcolor">
                <div className="container-fluid">
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-6">
                            <div className="studiomap">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15955.094026665618!2d103.83569568715818!3d1.3112635000000163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19aae47d31a3%3A0x8ea136677a6b09a6!2sDance%20Embassy!5e0!3m2!1sen!2sin!4v1725649915761!5m2!1sen!2sin" width="100%" height="450" title='Dance Embassy' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="studioaddress">
                                <div className="mainaddresshead">
                                    <h2>Desi-ductive & Desi-mix @ Serangoon Road</h2>
                                    <h3>Tokki Studio</h3>
                                </div>
                                <div className="addresslocation">
                                    <p><FaLocationDot/> Tokki Studio, 291 Serangoon Road, #04 – 02, Singapore 218107</p>
                                    <p><FaRegClock/> Every Saturday @ 1.30pm & 2.30pm respectively</p>
                                </div>
                                <div className="addressbtns">
                                    <Link to="#">More Info</Link>
                                    <Link to="https://momence.com/u/danceembassy" target='_blank'>Book a Class</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
};