import React, { useState } from "react";
import { ClientsLists } from "../../Api/clients";
import Sliders from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ourclient.css";
import MagicSliderDots from 'react-magic-slider-dots';
import 'react-magic-slider-dots/dist/magic-dots.css';

const clientsetting = {
  dots: true,
  infinite: true,
  speed: 200,
  autoplay: true,
  autoplaySpeed: 10000,
  slidesToShow: 5,
  slidesToScroll: 1,
  pauseOnHover: false,
  arrows: false,
  appendDots: dots => {
    return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={30} />;
  },
  responsive: [
    {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
        },
    },
    {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
    },
  ]
};

export default function Clinets() {
  const [Clientlist, setClients] = useState([]);
  return (
    <React.Fragment>
      <ClientsLists onFetch={setClients} />
      <section className="ourclients">
          <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                      <div className="clientheaidng">
                          <h2>Our Clients</h2>
                      </div>
                  </div>
              </div>
          </div>
          <div className="container">
              <div className="clientsslider">
                  <Sliders {...clientsetting}>
                      {Clientlist.map((client) => (
                          <div className="clientimgbox" key={client.id}>
                              <div className="clientimgs">
                                  <img src={client.logo} alt={client.name} />
                              </div>
                          </div>
                      ))}
                  </Sliders>
              </div>
          </div>
      </section>
    </React.Fragment>
  );
};