import React, {useState} from "react";
import './footer.css';
import { Link } from "react-router-dom";
import WhiteLogo from "../../assets/images/whitelogo.svg";
import FeaturedIn from "../../Components/Featuredin/featuredin";
import {WebsiteSettings} from "../../Api/pagedata";

export default function Footer() {
    const [Websitedata, setWebsiteSetting] = useState([]);
    return (
        <React.Fragment>
        <WebsiteSettings onFetch={setWebsiteSetting} />
        <FeaturedIn/>
        <footer className="mainfooter">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-2">
                        <div className="footerbox">
                            <Link to="/"><img src={WhiteLogo} alt="Main Logo" width="150" height="150"/></Link>
                            <p>Your one-stop platform for all your Dance, Fitness and Wellness needs.</p>
                        </div>
                    </div>
                    <div className="col-lg-10">
                        <div className="topfooter">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                                    <div className="footerbox">
                                        <div className="footerheading">
                                            <h2>Join our Tribe</h2>
                                        </div>
                                        <div className="footerdata">
                                            <ul>
                                                <li><Link to="/studio-locations/">Studio Locations</Link></li>
                                                <li><Link to="#">Pricing</Link></li>
                                                <li><Link to="/class-schedule/">Class Schedule</Link></li>
                                                <li><Link to="#">BollyChoreo @ Balmoral Plaza</Link></li>
                                                <li><Link to="#">BollyFreak @ Phil’s Studio (High Street Centre)</Link></li>
                                                <li><Link to="#">Desiductive & Desimix @ Tiny Studio</Link></li>
                                                <li><Link to="https://bollyfreak.gumroad.com/" target="_blank">On Demand Videos</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12">
                                    <div className="footerbox">
                                        <div className="footerheading">
                                            <h2>About DE</h2>
                                        </div>
                                        <div className="footerdata">
                                            <ul>
                                                <li><Link to="/our-story/">Our Story</Link></li>
                                                <li><Link to="/faq/">FAQ</Link></li>
                                                <li><Link to="/blog/">Blog</Link></li>
                                                <li><Link to="/contact-us/">Contact Us</Link></li>
                                                <li><Link to="https://www.instagram.com/danceembassy/?hl=en" target="_blank">Join Our Online Community</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12">
                                    <div className="footerbox">
                                        <div className="footerheading">
                                            <h2>Be Our Ambassador</h2>
                                        </div>
                                        <div className="footerdata">
                                            <ul>
                                                <li><Link to="#">Our Ambassadors</Link></li>
                                                <li><Link to="#">BollyFreak Training</Link></li>
                                                <li><Link to="#">Join Us!</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-12 col-xs-12 col-12">
                                    <div className="footerbox">
                                        <div className="footerheading">
                                            <h2>Giving Back</h2>
                                        </div>
                                        <div className="footerdata">
                                            <ul>
                                                <li><Link to="https://kriyaidsg.com/" target="_blank">Kriyaid</Link></li>
                                                <li><Link to="https://www.sos.org.sg/" target="_blank">Samaritans Of Singapore</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footerbottom">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="footerbox">
                                        <div className="footerheading">
                                            <h2>Performances And Entertainment</h2>
                                        </div>
                                        <div className="footerdata">
                                            <ul>
                                                <li><Link to="/team-building-workshops/">Corporate Dance & Audience Entertainment</Link></li>
                                                <li><Link to="/contact-us/">Global Events</Link></li>
                                                <li><Link to="/wedding-dance/">Wedding Dance</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="footerbox">
                                        <div className="footerheading">
                                            <h2>Workshops</h2>
                                        </div>
                                        <div className="footerdata">
                                            <ul>
                                                <li><Link to="/kids-dance-classes-and-parties/">Kids Dance Classes And Parties</Link></li>
                                                <li><Link to="/private-parties-and-lessons/">Private Parties And Lessons</Link></li>
                                                <li><Link to="/private-parties-and-lessons/">School Workshops</Link></li>
                                                <li><Link to="/team-building-workshops/">Team Building Workshops</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="footerbox">
                                        <div className="footerheading">
                                            <h2>Contact us</h2>
                                        </div>
                                        <div className="contactfooter">
                                            <ul>
                                                <li><i className="fa-regular fa-envelope"></i> <a href={'mailto:'+Websitedata.email_id}>{Websitedata.email_id}</a></li>
                                                <li><i className="fa-solid fa-phone-volume"></i> <a href={'tel:'+Websitedata.phone_number}>{Websitedata.phone_number}</a></li>
                                                <li><i className="fa-brands fa-whatsapp"></i> <Link to={'https://wa.me/'+Websitedata.whatsapp_url} target="_blank">Connect On WhatsApp</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <section className="footercopyright">
            <div className="container">
                <div className="copyrightext">
                    <p>{Websitedata.copyright} | Developed by <Link to="https://bluedigitaltechnologies.com/" target="_blank">Blue Digital Technologies.</Link></p>
                </div>
            </div>
        </section>
        </React.Fragment>
    );
}