
const API_URL = "https://cadmin.danceembassy.com";
// const API_URL = "http://localhost/dance-embassy";
const APP_URL = "https://www.danceembassy.com";

const Apis = {
  InstagramFeed: `${API_URL}/api/instagram-feed`,
  PageData: `${API_URL}/api/page-data`,
  Websitesetting: `${API_URL}/api/setting-data`,
  ClientsList: `${API_URL}/api/clients-list`,
  FeaturedList: `${API_URL}/api/featured-list`,
  ContactForms: `${API_URL}/api/contactform`
};
export { API_URL, Apis, APP_URL };