import axios from "axios";
import { Apis } from './api_url';

const ContactForm = async (formvalue) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    try {
        const response = await axios.post(Apis.ContactForms, { formvalue }, {
            headers: {
                'Authorization': apiKey,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching contact form data:', error);
        throw error;
    }
};
export { ContactForm };