import { useEffect } from "react";
import axios from "axios";
import { Apis } from './api_url';

const InstagramFeed = ({ onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        const fetchInstagram = async () => {
            try {
                const response = await axios.get(Apis.InstagramFeed, {
                    headers: { 'Authorization': apiKey}
                });
                onFetch(response.data);
            } catch (error) {
                console.error(error);
                onFetch([], false);
            }
        };
        fetchInstagram();
    }, [apiKey, onFetch]);

    return null;
}

export default InstagramFeed;