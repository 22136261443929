import React, { useState } from 'react';
import { PageMeta } from "../../Api/pagedata";
import {Helmet} from "react-helmet";
import "./ourstory.css";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import popupimg from "../../assets/images/imagecele.jpeg";
import { Link, useLocation } from "react-router-dom";
import Clients from "../../Components/Ourclient/ourclient";
import { Modal, Button } from 'react-bootstrap';
import YouTube from 'react-youtube';
import Findus from "../../Components/Findus/findus";
import { ReactComponent as Youtubeicon } from "../../assets/images/youtubevideo.svg";

export default function OurStory() {
    const [isOpen, setIsOpen] = useState(false);
    const [videoId, setVideoId] = useState('');
    const handleClose = () => setIsOpen(false);
    const openModal = (id) => {
        setVideoId(id);
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
        setVideoId('');
    };
    const opts = {
        height: '500px',
        width: '100%',
        playerVars: {
            autoplay: 1, 
            rel: 0, 
        },
    };
    const [PageMetas, setPageMeta] = useState({});
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const secondSegment = pathSegments[0] || '';
    return(
        <React.Fragment>
            <PageMeta currentpageurl={secondSegment} onFetch={setPageMeta} />
            <Helmet>
                <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Our Story | Dance Embassy'}</title>
                <meta name="description" content={PageMetas.metadescription || ''} />
                <meta name="keywords" content={PageMetas.metakeyword || ''} />
            </Helmet>
            <Navbar />
            <section className="breadcrumbarea">
                <div className="container">
                    <div className="breadcrumhead">
                        <h1>Our Story</h1>
                    </div>
                </div>
            </section>
            <section className="ourstory">
                <div className="row align-items-center g-0">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div className="ourstorycotnent">
                            <h2>About Dance Embassy</h2>
                            <p>Since 2012, Dance Embassy has been the go-to company for all things dance, fitness, and wellness. We have been featured in over 100 media channels and press, hosted over 1000 workshops, classes, and weddings, and have a growing community of over 10,000 individuals across all our social media platforms! We have broken barriers and shattered glass ceilings. One of which was releasing the World’s first <Link to="https://www.audible.com/search?searchNarrator=Lavania+Hajji" target='_blank'>dance fitness podcast with Audible</Link> during the COVID-19 Lockdown period.</p>
                            <p>We are thankful to our clients, instructors, and students for the unwavering love and support. Our goal is to continue to stay relevant and push boundaries. We believe in quality over quantity, and each of our Instructors is the epitome of professionalism, talent, and most importantly, they are community builders.</p>
                            <p>We trust that once you are a part of the DE (Dance Embassy) tribe, rest assured you will feel like you belong, just like Family.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div className="ourstoryimage">
                            <img src={popupimg} alt="ourstory" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="getsocial">
                <div className="container">
                    <div className="getsocialhead">
                        <h2>Get Social with Us</h2>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="getsocialbox">
                                <img src="https://img.youtube.com/vi/ZH3JWVES7sU/0.jpg" alt="YouTube Thumbnail" onClick={() => openModal('ZH3JWVES7sU')} style={{ cursor: 'pointer' }} />
                                <div className="videobtns" onClick={() => openModal('ZH3JWVES7sU')}><Youtubeicon/></div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="getsocialbox">
                                <img src="https://img.youtube.com/vi/K8B5PvnpS3E/0.jpg" alt="YouTube Thumbnail" onClick={() => openModal('K8B5PvnpS3E')} style={{ cursor: 'pointer' }} />
                                <div className="videobtns" onClick={() => openModal('K8B5PvnpS3E')}><Youtubeicon/></div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="getsocialbox">
                                <img src="https://img.youtube.com/vi/WCOaSDhBcXw/0.jpg" alt="YouTube Thumbnail" onClick={() => openModal('WCOaSDhBcXw')} style={{ cursor: 'pointer' }} />
                                <div className="videobtns" onClick={() => openModal('WCOaSDhBcXw')}><Youtubeicon/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal show={isOpen} onHide={closeModal} centered size="lg" dialogClassName="video-popup">
                <Modal.Body>
                    <div className="videopopupclose">
                        <Button variant="close" onClick={handleClose} aria-label="Close"></Button>
                    </div>
                    {videoId && <YouTube videoId={videoId} opts={opts} />}
                </Modal.Body>
            </Modal>
            <Findus/>
            <Clients />
            <Footer />
        </React.Fragment>
    );
}
