import React, {useState} from "react";
import { PageMeta } from "../../../Api/pagedata";
import {Helmet} from "react-helmet";
import Navbar from '../../../Components/Navbar/navbar';
import { Link, useLocation } from 'react-router-dom';
import "./retreats.css";
import Footer from "../../../Components/Footer/footer";
import Recharge from "../../../assets/images/recharege.jpg";
import Refocus from "../../../assets/images/refocus.jpg";
import Workforce from "../../../assets/images/workforce.jpg";

export default function Retreats(){
    const [PageMetas, setPageMeta] = useState({});
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const secondSegment = pathSegments[0] || '';
    return(
        <React.Fragment>
            <PageMeta currentpageurl={secondSegment} onFetch={setPageMeta} />
            <Helmet>
                <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Retreats | Dance Embassy'}</title>
                <meta name="description" content={PageMetas.metadescription || ''} />
                <meta name="keywords" content={PageMetas.metakeyword || ''} />
            </Helmet>
            <Navbar />
            <section className="breadcrumbarea">
                <div className="container">
                    <div className="breadcrumhead">
                        <h1>Retreats</h1>
                    </div>
                </div>
            </section>
            <section className="recharge">
                <div className="container-fluid">
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-6">
                            <div className="retreatimg">
                                <img src={Recharge} alt="Recharege" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="retreatcontent">
                                <h2>Recharge & Reconnect:</h2>
                                <ul>
                                    <li>Unleash Peak Performance with Health Travel Retreats</li>
                                    <li>Feeling a productivity slump?  Is teamwork a struggle?</li>
                                    <li>Our Corporate Wellness Retreats are the answer!  We craft transformative experiences that empower your team to recharge, reconnect, and reach peak performance.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="recharge refocus">
                <div className="container-fluid">
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-6">
                            <div className="retreatcontent">
                                <h2>Unwind & Refocus:</h2>
                                <ul>
                                    <li><b>Tailored Retreats:</b> From stress-busting getaways to team-building adventures, we design retreats to fit your specific needs.</li>
                                    <li><b>Holistic Approach:</b> We go beyond physical fitness, nurturing mental, emotional, and physical well-being.</li>
                                    <li><b>Expert Partners:</b> Decades of travel expertise paired with world-class wellness resorts guarantee a rejuvenating experience.</li>
                                    <li><b>Global Reach:</b> Choose from stunning locations worldwide, fostering a refreshing change of scenery.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="retreatimg">
                                <img src={Refocus} alt="Recharege" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="recharge invest">
                <div className="container-fluid">
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-6">
                            <div className="retreatimg">
                                <img src={Workforce} alt="Recharege" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="retreatcontent">
                                <h2>Invest in a Thriving Workforce:</h2>
                                <ul>
                                    <li><b>Boost Morale & Engagement:</b> Happy employees are productive employees.</li>
                                    <li><b>Combat Burnout & Stress:</b> Help your team recharge and return energized.</li>
                                    <li><b>Strengthen Teamwork:</b> Foster communication and collaboration in a relaxed setting.</li>
                                    <li><b>Promote Work-Life Balance:</b> Invest in your employees' well-being for long-term success.</li>
                                    <li><b>Health Travel Retreats:</b> Where wellness meets peak performance.</li>
                                </ul>
                                <p><Link to="/contact-us/">Contact us</Link> today and unlock your team's full potential!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
};