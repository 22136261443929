import React, {useState} from "react";
import { PageMeta } from "../../../Api/pagedata";
import {Helmet} from "react-helmet";
import Navbar from '../../../Components/Navbar/navbar';
import zumbaclass from '../../../assets/images/zumba_class.webp';
import zumba_img from '../../../assets/images/zumba_img.jpg';
import "./zumba.css";
import Footer from "../../../Components/Footer/footer";
import { useLocation } from 'react-router-dom';
export default function ZumbaClasses() {
  const [PageMetas, setPageMeta] = useState({});
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
  const secondSegment = pathSegments[0] || '';
  return(
    <React.Fragment>
      <PageMeta currentpageurl={secondSegment} onFetch={setPageMeta} />
      <Helmet>
          <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Zumba Classes | Dance Embassy'}</title>
          <meta name="description" content={PageMetas.metadescription || ''} />
          <meta name="keywords" content={PageMetas.metakeyword || ''} />
      </Helmet>
      <Navbar />
      <section className="breadcrumbarea">
        <div className="container">
          <div className="breadcrumhead">
            <h1>Zumba Classes</h1>
          </div>
        </div>
      </section>
      <section className="zumba_bg">
        <div className="container-fluid pl-lg-0">
          <div className="row g-0 align-items-center">
            <div className="col-lg-6">
              <div className="retreatimg">
                <img src={zumbaclass} alt="zumba" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="retreatcontent bg_color">
                  <h2>What to look for in a Zumba instructor</h2>
                  <div className='dance_content'>
                    <p>If you’re interested in taking Zumba classes, you’ll want to make sure that your instructor is knowledgeable and experienced. You’ll also want to make sure that the instructor is professional and organized. Finally, you’ll want to be sure that the instructor and studio is welcoming and friendly. Read on for ten essential facts you need to know about Zumba:</p>
                    <p>Below, you’ll find ten essential facts you need to know before trying Zumba classes or taking online training. If you really want to give Zumba a spin, be sure to follow these tips. You’ll be glad you did!</p>
                    <div className='mt-3'>
                      <small>
                        <strong>FINDING YOUR ZUMBA CLASS</strong>
                        <i><p>If you’ve taken Zumba classes in the past, your instructor should have a lot of good information to share. It’s worth asking your instructor what keeps him or her busy. Some instructors always seem to have tons of upcoming and upcoming shows coming up. Others have a steady rotation of classes and constantly work on their current class or that big commercial they’re putting in the front yard. Always ask your instructor what keeps them busy. Be as specific as possible about what keeps them busy. The last thing you want is to be sent somewhere because your instructor doesn’t have time for your questions.</p></i>
                      </small>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="zumba_bg zumba_bg2">
        <div className="container-fluid pl-lg-0">
          <div className="row g-0 align-items-center">
            <div className="col-lg-6">
              <div className="retreatcontent bg_color">
                <h2>How to find the best Zumba dance classes in your area</h2>
                <div className='dance_content'>
                  <p>If you’re looking for a fun way to get in shape, Zumba may be the perfect exercise for you. Zumba is a Latin-inspired dance workout that’s all about having fun and getting fit at the same time. Pictured above is the standard version of the Zumba workout. It includes a barbell, a pair of sneakers and two resistance bands. While the standard version of the workout includes 4–10 minutes of intervals, we highly recommend you check out the intermediate, advanced and speed workouts to see why these become essential on any exercise routine.</p>
                  <p>Let’s not forget about quality, though. In addition to getting a great workout, these Zumba videos will help boost your confidence, too. Be sure to watch all of these videos and additions to see why this workout is a great option for both beginners and more advanced exercisers.</p>
                  <p>Don’t forget to hydrate along with your Zumba workout. There are liquids that can help aid your muscles in getting the electricity they need to perform.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="retreatimg">
                <img src={zumba_img} alt="zumba" style={{ width: '100%', height: 'auto' }}  />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};