import React, {useState} from "react";
import { PageMeta } from "../../../Api/pagedata";
import {Helmet} from "react-helmet";
import Navbar from '../../../Components/Navbar/navbar';
import bollywoodkids from '../../../assets/images/bollywood_kids.jpg';
import bollywood from '../../../assets/images/bollywood.jpg';
import Footer from "../../../Components/Footer/footer";
import { useLocation } from "react-router-dom";

export default function Bollywood() {
  const [PageMetas, setPageMeta] = useState({});
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
  const secondSegment = pathSegments[0] || '';
  return(
    <React.Fragment>
      <PageMeta currentpageurl={secondSegment} onFetch={setPageMeta} />
      <Helmet>
          <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Bollywood For Kids | Dance Embassy'}</title>
          <meta name="description" content={PageMetas.metadescription || ''} />
          <meta name="keywords" content={PageMetas.metakeyword || ''} />
      </Helmet>
      <Navbar />
      <section className="breadcrumbarea">
        <div className="container">
          <div className="breadcrumhead">
            <h1>Bollywood For Kids </h1>
          </div>
        </div>
      </section>
      <section className="zumba_bg">
        <div className="container-fluid pl-lg-0">
            <div className="row g-0 align-items-center">
                <div className="col-lg-6">
                    <div className="retreatimg">
                        <img src={bollywoodkids} alt="zumba" style={{ width: '100%', height: 'auto' }}  />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="retreatcontent bg_color">
                        <h2>The top dance Classes for kids in Singapore</h2>
                        <div className='dance_content'>
                      
                      <p>With the rise of online learning platforms, it’s easy to forget that there are still many things that you learn best by interacting with another human being. And one of these is dancing. Kids can’t learn all the intricate moves they see on TV or on the stage without a great teacher — and we’ve got everything you need to know about teaching your kids how to dance right here for you! If you’re looking for extra-curricular activities for your kids, or just want to give them a head-start in the world of dance, here are the top dance schools in Singapore visit danceembassy.com and choose best Kids Dance Classes in Singapore.</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
      <section className="zumba_bg zumba_bg2 bollwybood">
        <div className="container-fluid pl-lg-0">
            <div className="row g-0 align-items-center">
              <div className="col-lg-6">
                <div className="retreatcontent bg_color">
                    <h2>What do you need to look out for when picking a dance school?</h2>
                    <div className='dance_content'>
                      <p>If you’re looking to take dance lessons for the first time, you might want to keep these tips in mind:</p>
                      <ul className='mt-2'>
                        <li>Look for reputable Kids Dance Classes in Singapore. Reputation is the most important thing when it comes to picking a dance school. You want to make sure that the school you choose has a great reputation. There are also online dance schools, but they are not regulated or endorsed by the Better Business Bureau. Ultimately, you are the choosing school, so be sure to ask the school if they have a reputation before you make your decision .</li>
                        <li>Wear a lot of interesting costumes. Your children will get very confused if you bring them to the circus without throwing on a mask and dancing the jig.</li>
                        <li>Learn from your mistakes. Try doing 5 reps per side to beginning at your home gym, then try doing 10 reps per side at the school’s free workout class. Make mistakes! Just try and figure out your level and use that as a baseline, but don’t make your mistakes permanent! You can always come back to them and do them a little differently next time!</li>
                        <li>Calculate your misses. Think about this — would you get frustrated if you saw your kid do something wrong? Then, ask your spouse or whoever is watching you in case you make the mistake. To make it as easy as possible for yourself, jot this down and write it down next to each “miss” in your exercise log.</li>
                      </ul>
                    </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="retreatimg">
                  <img src={bollywood} alt="zumba"/>
                </div>
              </div>
            </div>
        </div>
      </section>
    <Footer />
    </React.Fragment>
  );
};