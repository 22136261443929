import React from "react";
import Sliders from "react-slick";

import slider1 from "../../assets/images/slider/slider1.webp";
import slider2 from "../../assets/images/slider/slider2.webp";
import slider3 from "../../assets/images/slider/slider3.webp";
import slider4 from "../../assets/images/slider/slider4.webp";
import slider5 from "../../assets/images/slider/slider5.webp";
import slider6 from "../../assets/images/slider/slider6.webp";
import slider7 from "../../assets/images/slider/slider7.webp";
import slider8 from "../../assets/images/slider/slider8.webp";
import slider9 from "../../assets/images/slider/slider9.webp";
import "./slider.css";

const slides = [
  { id: 1, image: slider1 },
  { id: 2, image: slider2 },
  { id: 3, image: slider3 },
  { id: 4, image: slider4 },
  { id: 5, image: slider5 },
  { id: 6, image: slider6 },
  { id: 7, image: slider7 },
  { id: 8, image: slider8 },
  { id: 9, image: slider9 },
];

const settings = {
  dots: false,
  infinite: true,
  speed: 200,
  autoplay: true,
  autoplaySpeed: 200,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  pauseOnHover: false,
  arrows: false,
};

export default function Slider() {
  return (
    <section className="sliderarea">
      <Sliders {...settings}>
        {slides.map((slide) => (
          <div key={slide.id}>
            <img src={slide.image} alt={`Slide ${slide.id}`} />
          </div>
        ))}
      </Sliders>
    </section>
  );
};