import { useEffect } from "react";
import axios from "axios";
import { Apis } from './api_url';

const PageMeta = ({ currentpageurl, onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        if (currentpageurl) {
            const fetchPageMeta = async () => {
                try {
                    const response = await axios.post(Apis.PageData, { url: currentpageurl }, {
                        headers: { 'Authorization': apiKey, 'Content-Type': 'application/json' }
                    });
                    if (onFetch) {
                        onFetch(response.data.pages);
                    }
                } catch (error) {
                    console.error('Error fetching page metadata:', error);
                }
            };
            fetchPageMeta();
        }
    }, [currentpageurl, apiKey, onFetch]);
    return null;
};
const WebsiteSettings = ({ onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        const fetchPageMeta = async () => {
            try {
                const response = await axios.get(Apis.Websitesetting, {
                    headers: { 'Authorization': apiKey}
                });
                if (onFetch) {
                    onFetch(response.data.settings);
                }
            } catch (error) {
                console.error('Error fetching page metadata:', error);
            }
        };
        fetchPageMeta();
    }, [apiKey, onFetch]);
    return null;
};
export { PageMeta, WebsiteSettings };