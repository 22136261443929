import React, {useState} from "react";
import { PageMeta } from "../../../Api/pagedata";
import {Helmet} from "react-helmet";
import Navbar from '../../../Components/Navbar/navbar';
import { Link, useLocation } from 'react-router-dom';
import "./employeewell.css";
import Footer from "../../../Components/Footer/footer";
import Productivity from "../../../assets/images/Morale.webp";
import Workshop from "../../../assets/images/diverse.webp";

export default function EmployeeWelness(){
    const [PageMetas, setPageMeta] = useState({});
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const secondSegment = pathSegments[0] || '';
    return(
        <React.Fragment>
            <PageMeta currentpageurl={secondSegment} onFetch={setPageMeta} />
            <Helmet>
                <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Employee Wellness | Dance Embassy'}</title>
                <meta name="description" content={PageMetas.metadescription || ''} />
                <meta name="keywords" content={PageMetas.metakeyword || ''} />
            </Helmet>
            <Navbar />
            <section className="breadcrumbarea">
                <div className="container">
                    <div className="breadcrumhead">
                        <h1>Employee Wellness</h1>
                    </div>
                </div>
            </section>
            <section className="welness">
                <div className="container">
                    <div className="wellnewsshead">
                        <h4>Invest in the well-being of your employees and unlock their full potential with our curated selection of Wellness Workshops.</h4>
                        <p>We partner with industry experts to deliver engaging and effective programs that cater to a wide range of needs.</p>
                    </div>
                </div>
            </section>
            <section className="recharge productivity">
                <div className="container-fluid">
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-6">
                            <div className="retreatimg">
                                <img src={Productivity} alt="Recharege" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="retreatcontent">
                                <h2>Boost Employee Morale and Productivity:</h2>
                                <ul>
                                    <li><b>Reduced Stress and Burnout:</b> Equip your team with practical techniques to manage stress and prevent burnout.</li>
                                    <li><b>Enhanced Focus and Performance:</b> Foster mindfulness and concentration through workshops on meditation and breathing exercises.</li>
                                    <li><b>Improved Communication and Collaboration:</b> Encourage self-care and healthy habits, leading to a more positive and productive work environment.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="recharge refocus workshop">
                <div className="container-fluid">
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-6">
                            <div className="retreatcontent">
                                <h2>Our Diverse Workshop Offerings:</h2>
                                <ul>
                                    <li><b>Wellness Techniques:</b> Explore a variety of practices for holistic well-being.</li>
                                    <li><b>EFT Tapping:</b> Learn this powerful tool for emotional balancing and stress relief.</li>
                                    <li><b>Wellness Healing:</b> Discover a range of practices to promote physical and mental well-being.</li>
                                    <li><b>Healthy Cooking Demonstrations:</b> Foster healthy eating habits with interactive cooking sessions.</li>
                                    <li><b>Mindfulness and Meditation (Non-Religious):</b> Cultivate present-moment awareness and focus.</li>
                                    <li><b>Pre- and Post-Natal Workshops:</b> Support expecting and new mothers with tailored guidance.</li>
                                    <li><b>Women-Focused Workshops:</b> Address the unique needs and challenges faced by women in the workplace.</li>
                                    <li><b>Self-Care Sessions:</b>
                                        <ul>
                                            <li><b>Stress Management:</b> Learn practical strategies to combat stress and find calm.</li>
                                            <li><b>Sound Healing:</b> Experience the restorative power of sound vibrations.</li>
                                            <li><b>Art Therapy:</b> Discover a creative outlet for self-expression and emotional exploration.</li>
                                            <li><b>Art of Journaling:</b> Develop journaling techniques for self-reflection and goal setting.</li>
                                            <li><b>Ice Baths (optional):</b> Explore the potential benefits of cold therapy for recovery and resilience (consult with medical professionals beforehand).</li>
                                            <li><b>Breathing Techniques:</b> Master effective breathing exercises for relaxation and focus.</li>
                                        </ul>
                                    </li>
                                </ul>
                                <p>We take a personalized approach, working with you to curate a workshop program that aligns with your company's specific needs and goals.</p>
                                <p><Link to="/contact-us/">Contact us</Link> today to discuss how our Wellness Workshops can empower your workforce and create a thriving workplace culture!</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="retreatimg">
                                <img src={Workshop} alt="Recharege" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
};
