import { useEffect } from "react";
import axios from "axios";
import { Apis } from './api_url';

const FeaturedLists = ({ onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await axios.get(Apis.FeaturedList, {
                    headers: { 'Authorization': apiKey}
                });
                onFetch(response.data.clients);
            } catch (error) {
                console.error(error);
                onFetch([], false);
            }
        };
        fetchClients();
    }, [apiKey, onFetch]);

    return null;
}
const ClientsLists = ({ onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await axios.get(Apis.ClientsList, {
                    headers: { 'Authorization': apiKey}
                });
                onFetch(response.data.clients);
            } catch (error) {
                console.error(error);
                onFetch([], false);
            }
        };
        fetchClients();
    }, [apiKey, onFetch]);

    return null;
}

export { FeaturedLists, ClientsLists };