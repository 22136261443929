import React, {useState} from "react";
import { PageMeta } from "../../../Api/pagedata";
import {Helmet} from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import "./teambuilding.css";
import Navbar from "../../../Components/Navbar/navbar";
import Footer from "../../../Components/Footer/footer";
import Clietns from "../../../Components/Ourclient/ourclient";
import Passionate from "../../../assets/images/passionate.jpg";
import Uniquely from "../../../assets/images/uniquely.png";
import Unlimited from "../../../assets/images/unlimited.jpg";
import { Modal, Button } from 'react-bootstrap';
import YouTube from 'react-youtube';
import { ReactComponent as Youtubeicon } from "../../../assets/images/youtubevideo.svg";
import Mixology from "../../../assets/images/mixology.jpg";
import Testimonails from "../../../Components/Reviews/review";
import Accordion from 'react-bootstrap/Accordion';
import Corportategallery from "../../../assets/images/corporategallery.jpg";
import Morequestions from "../../../assets/images/thingking.jpeg";

export default function Teambuilding(){
    const [isOpen, setIsOpen] = useState(false);
    const [videoId, setVideoId] = useState('');
    const handleClose = () => setIsOpen(false);
    const openModal = (id) => {
        setVideoId(id);
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
        setVideoId('');
    };
    const opts = {
        height: '500px',
        width: '100%',
        playerVars: {
            autoplay: 1, 
            rel: 0, 
        },
    };
    const [PageMetas, setPageMeta] = useState({});
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const secondSegment = pathSegments[0] || '';
    return(
        <React.Fragment>
            <PageMeta currentpageurl={secondSegment} onFetch={setPageMeta} />
            <Helmet>
                <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Team Building Workshops | Dance Embassy'}</title>
                <meta name="description" content={PageMetas.metadescription || ''} />
                <meta name="keywords" content={PageMetas.metakeyword || ''} />
            </Helmet>
            <Navbar/>
            <section className="breadcrumbarea">
                <div className="container">
                    <div className="breadcrumhead">
                        <h1>Team Building Workshops</h1>
                    </div>
                </div>
            </section>
            <section className="teambuilding">
                <div className="container">
                    <div className="teambuildinghead">
                        <h2>Why is DE the best choice for your Corporate needs?</h2>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="teambuildinggrid">
                                <div className="teambuildimg">
                                    <img src={Passionate} alt="Passionate & Experienced Facilitators" />
                                </div>
                                <div className="teambuildcontent">
                                    <h3>Passionate & Experienced Facilitators</h3>
                                    <p>Our Ambassadors are well-trained and ready to create a unique experience for you and your team!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="teambuildinggrid">
                                <div className="teambuildimg">
                                    <img src={Uniquely} alt="Uniquely Curated Workshops" />
                                </div>
                                <div className="teambuildcontent">
                                    <h3>Uniquely Curated Workshops</h3>
                                    <p>Our Ambassadors are well-trained and ready to create a unique experience for you and your team!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="teambuildinggrid">
                                <div className="teambuildimg">
                                    <img src={Unlimited} alt="Unlimited Fun & Entertainment Guaranteed" />
                                </div>
                                <div className="teambuildcontent">
                                    <h3>Unlimited Fun & Entertainment Guaranteed</h3>
                                    <p>Our Ambassadors are well-trained and ready to create a unique experience for you and your team!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="teambuildvideo">
                <div className="container-fluid">
                    <div className="row justify-content-center align-items-center g-0">
                        <div className="col-lg-6">
                            <div className="getsocialbox">
                                <img src="https://img.youtube.com/vi/NH5hupPL2Jo/0.jpg" alt="YouTube Thumbnail" onClick={() => openModal('NH5hupPL2Jo')} style={{ cursor: 'pointer' }} />
                                <div className="videobtns" onClick={() => openModal('NH5hupPL2Jo')}><Youtubeicon/></div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="teambuildhowit">
                                <h2>How it works?</h2>
                                <ol>
                                    <li>Our free Demo involves a DE team member giving you a run through of what the workshop/session will entail.</li>
                                    <li>We will discuss your Team’s needs & budget and customise your session appropriately.</li>
                                    <li>With DE, introduce your workplace to a variety of Dance & Wellness workshops and sessions such as Salsa, Zumba, Bollywood, Bellydance, Hip-Hop, K-Pop, Meditation, Pilates, and even upcoming dance forms such as African Dance.</li>
                                </ol>
                                <p>Whether it’s the teaching of Classical Kathak moves or TikTok dance moves, your team’s workshop is sure to be a HIT!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal show={isOpen} onHide={closeModal} centered size="lg" dialogClassName="video-popup">
                <Modal.Body>
                    <div className="videopopupclose">
                        <Button variant="close" onClick={handleClose} aria-label="Close"></Button>
                    </div>
                    {videoId && <YouTube videoId={videoId} opts={opts} />}
                </Modal.Body>
            </Modal>
            <section className="mixology">
                <div className="container-fluid">
                    <div className="row align-items-center g-0">
                        <div className="col-lg-6">
                            <div className="mixologycontent">
                                <h2>Work & Play Mixology</h2>
                                <p>With DE your team will be in safe hands!</p>
                                <p>Our facilitators are well-trained and are able to cater to your Company’s needs.</p>
                                <p>We offer services such as:</p>
                                <ul>
                                    <li>Team-Building Workshops (Virtual & In-Studio)</li>
                                    <li>Weekly Classes for your Team (Virtual & In-Studio)</li>
                                    <li>Dance Performances for Company Dinner & Dance</li>
                                </ul>
                                <p>We tailor programs based on your Budget!</p>
                                <p>Click to schedule your FREE Demo</p>
                                <div className="mixologycta">
                                    <Link to="/contact-us/">Contact us</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mixologyimg">
                                <img src={Mixology} alt="Mixology" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Testimonails/>
            <section className="gallery">
                <div className="container-fluid">
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-6">
                            <div className="galleryarea">
                                <img src={Corportategallery} alt="Corportae img" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="faqsareateam">
                                <h2>FAQ</h2>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>How do I book DE for a Corporate session?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Kindly fill up the <Link to="/contact-us/">contact form</Link> with the subject: “Free demo for Corporate”.</p>
                                            <p>A DE consultant will then reach out to discuss further. During this call, we aim to understand your needs and thus enabling us to ensure your session is a success.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>What can I expect in a DE Team Building session?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Curation of the workshop/session is dependant on the expectations and goals of what you would like to achieve.</p>
                                            <p>We will recommend a few ideas for you to explore and ultimately you can decide what will be best suited for you and your team.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>I want to book more than one session, are there any packages available?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Of course, this can be discussed with our DE consultant.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Do you hold virtual team building workshops?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Yes, we do! We can advise you on the variety of engaging workshops we are able to offer.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>Are your team building workshops held in Singapore?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Yes, we do host most of our workshops in Singapore. However, we can plan Destination Workshops as well as per your requirements.</p>
                                            <p>(Due to COVID-19 this service is currently unavailable)</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>What are your top recommended workshops?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Our highly popular workshops are Bollywood dancing, followed by our African dance and meditational workshops.</p>
                                            <p>Check out our variety of classes <Link to="#">here</Link>.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>Do you offer venues for your team building workshops?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>We can facilitate these workshops at a venue of your choice, as well as make suggestions for potential venues according to your needs.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="morefaqs">
                <div className="container-fluid">
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-6">
                            <div className="morequesitons">
                                <h2>Have More Questions?</h2>
                                <p>If you have any further inquiries regarding any our DE services, please feel free to get in touch with us so we can help you further</p>
                                <div className="morequestioncts">
                                    <Link to="/contact-us/">Contact us</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="morquesitonimg">
                                <img src={Morequestions} alt="Faqs" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Clietns/>
            <Footer/>
        </React.Fragment>
    )
}