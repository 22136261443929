import React from "react";
import './findus.css';
import { Link } from "react-router-dom";

export default function Findus() {
    return (
        <React.Fragment>
        <section className="findus">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div className="findusbox">
                            <div className="findusheiang">
                                <h2>Find A Class</h2>
                            </div>
                            <div className="finduscontent">
                                <p>DE has got you covered with a myriad of classes to choose from. There is something for everyone!</p>
                            </div>
                            <div className="findusbtns">
                                <Link to="#">View Classes</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div className="findusbox">
                            <div className="findusheiang">
                                <h2>Meet Our Ambassadors</h2>
                            </div>
                            <div className="finduscontent">
                                <p>Our Ambassadors are stellar experts in their fields! Get to know about their story!</p>
                            </div>
                            <div className="findusbtns">
                                <Link to="#">Find out more about them!</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </React.Fragment>
    );
}