import React, {useState} from "react";
import { PageMeta } from "../../Api/pagedata";
import {Helmet} from "react-helmet";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./contact.css";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import { FaWhatsapp, FaEnvelopeOpenText } from "react-icons/fa6";
import { ContactForm } from "../../Api/formdata";

export default function Contactus(){
    const [PageMetas, setPageMeta] = useState({});
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const secondSegment = pathSegments[0] || '';

    /*---------Contact form query ----------- */
    const [formData, setFormData] = useState({
        full_name: '',
        phone: '',
        email: '',
        confirm_email: '',
        classes: [],
        comments: '',
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const validateForm = () => {
        const errors = {};
        if (!formData.full_name) {
            errors.full_name = 'Full Name is required';
        } else if (/\d/.test(formData.full_name)) {
            errors.full_name = 'Full Name should not contain digits';
        }
        if (!formData.phone) {
            errors.phone = 'Phone Number is required';
        } else if (!/^\d+$/.test(formData.phone)) {
            errors.phone = 'Phone Number should only contain digits';
        }
        if (!formData.email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Email is invalid';
        }
        if (!formData.confirm_email) {
            errors.confirm_email = 'Confirm Email is required';
        } else if (formData.email !== formData.confirm_email) {
            errors.confirm_email = 'Emails do not match';
        }
        const urlRegex = /((http|https):\/\/[^\s]+)|(www\.[^\s]+)/g;
        if (urlRegex.test(formData.comments)) {
            errors.comments = 'Comments should not contain URLs';
        }
        return errors;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }
        setLoading(true);
        setErrorMessage('');
        try {
            const data = await ContactForm(formData);
            console.log('Form submitted successfully:', data);
            setFormData({
                full_name: '',
                phone: '',
                email: '',
                confirm_email: '',
                classes: [],
                comments: '',
            });
            setErrors({});
        } catch (error) {
            //console.error('Error submitting the form:', error);
            setErrorMessage('Something went wrong, please try again later.');
        } finally {
            navigate('/thank-you/');
            setLoading(false);
        }
    };
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData(prevFormData => ({
                ...prevFormData,
                classes: checked
                    ? [...prevFormData.classes, value]
                    : prevFormData.classes.filter(item => item !== value)
            }));
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    return(
        <React.Fragment>
            <PageMeta currentpageurl={secondSegment} onFetch={setPageMeta} />
            <Helmet>
                <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Contact Us | Dance Embassy'}</title>
                <meta name="description" content={PageMetas.metadescription || ''} />
                <meta name="keywords" content={PageMetas.metakeyword || ''} />
            </Helmet>
            <Navbar/>
                <section className="breadcrumbarea">
                    <div className="container">
                        <div className="breadcrumhead">
                            <h1>Contact Us</h1>
                        </div>
                    </div>
                </section>
                <section className="contactusarea">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="contactinfoara">
                                    <div className="contactusheaing">
                                        <h2>Get In Touch</h2>
                                        <p>For any further questions regarding our Services/App/Products, please do not hesitate to Contact Us!</p>
                                        <p>We look forward to hearing from you</p>
                                    </div>
                                    <div className="contactinfos">
                                        <div className="contactinfobox">
                                            <div className="contactinfoicon"><FaWhatsapp/></div>
                                            <div className="contactinfodata">
                                                <p><Link to="https://wa.me/send?phone=6581614082&text=I%27m%20interested%20in%20your%20services!" target="_blank">+65 8161 4082</Link></p>
                                            </div>
                                        </div>
                                        <div className="contactinfobox">
                                            <div className="contactinfoicon"><FaEnvelopeOpenText/></div>
                                            <div className="contactinfodata">
                                                <p><Link to="mailto:lavania@danceembassy.com">lavania@danceembassy.com</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="contactform">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="full_name">Full Name <span className="required">*</span></label>
                                                    <input type="text" id="full_name" name="full_name" className="form-control" placeholder="Full Name" onChange={handleChange}/>
                                                    {errors.full_name && <p className="error">{errors.full_name}</p>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="phone">Phone Number <span className="required">*</span></label>
                                                    <input type="text" id="phone" name="phone" className="form-control" placeholder="Phone Number" onChange={handleChange}/>
                                                    {errors.phone && <p className="error">{errors.phone}</p>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="email">Your Email <span className="required">*</span></label>
                                                    <input type="email" id="email" name="email" className="form-control" placeholder="Your Email" onChange={handleChange}/>
                                                    {errors.email && <p className="error">{errors.email}</p>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="confirm_email">Confirm Your Email <span className="required">*</span></label>
                                                    <input type="email" id="confirm_email" name="confirm_email" className="form-control" placeholder="Confirm Your Email" onChange={handleChange}/>
                                                    {errors.confirm_email && <p className="error">{errors.confirm_email}</p>}
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="checkboxarea">
                                                    <p>To which category does your query relate? (please tick box)</p>
                                                    <div className="form-checkbox">
                                                        <input type="checkbox" id="bollywood_dance" value="Bollywood Dance Classes" name="classes[]" onChange={handleChange}/>
                                                        <label htmlFor="bollywood_dance">Bollywood Dance Classes</label>
                                                    </div>
                                                    <div className="form-checkbox">
                                                        <input type="checkbox" id="dance_fitness" value="Dance Fitness Classes" name="classes[]" onChange={handleChange}/>
                                                        <label htmlFor="dance_fitness">Dance Fitness Classes (BollyFreak, Zumba, African Dance Fitness, Salsation, Kpop etc)</label>
                                                    </div>
                                                    <div className="form-checkbox">
                                                        <input type="checkbox" id="professional_bollywood" value="Professional Bollywood Entertainment" name="classes[]" onChange={handleChange}/>
                                                        <label htmlFor="professional_bollywood">Professional Bollywood Entertainment</label>
                                                    </div>
                                                    <div className="form-checkbox">
                                                        <input type="checkbox" id="private_party" value="Private Party" name="classes[]" onChange={handleChange}/>
                                                        <label htmlFor="private_party">Private Party (Hens Party, Birthdays etc)</label>
                                                    </div>
                                                    <div className="form-checkbox">
                                                        <input type="checkbox" id="corporate_team" value="Corporate Team Building" name="classes[]" onChange={handleChange}/>
                                                        <label htmlFor="corporate_team">Corporate Team Building</label>
                                                    </div>
                                                    <div className="form-checkbox">
                                                        <input type="checkbox" id="widding_dance" value="Wedding Dance" name="classes[]" onChange={handleChange}/>
                                                        <label htmlFor="widding_dance">Wedding Dance (First Dance, Sangeet Group Dance etc)</label>
                                                    </div>
                                                    <div className="form-checkbox">
                                                        <input type="checkbox" id="school_workshops" value="School Workshops" name="classes[]" onChange={handleChange}/>
                                                        <label htmlFor="school_workshops">School Workshops</label>
                                                    </div>
                                                    <div className="form-checkbox">
                                                        <input type="checkbox" id="kids_related" value="Kids Related" name="classes[]" onChange={handleChange}/>
                                                        <label htmlFor="kids_related">Kids Related (Dance Classes, Birthday parties etc)</label>
                                                    </div>
                                                    <div className="form-checkbox">
                                                        <input type="checkbox" id="group_private" value="Group Private Lessons" name="classes[]" onChange={handleChange}/>
                                                        <label htmlFor="group_private">Group Private Lessons</label>
                                                    </div>
                                                    <div className="form-checkbox">
                                                        <input type="checkbox" id="collaborations" value="Collaborations" name="classes[]" onChange={handleChange}/>
                                                        <label htmlFor="collaborations">Collaborations</label>
                                                    </div>
                                                    <div className="form-checkbox">
                                                        <input type="checkbox" id="media_press" value="Media / Press" name="classes[]" onChange={handleChange}/>
                                                        <label htmlFor="media_press">Media / Press</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label htmlFor="comments">Comments</label>
                                                    <textarea name="comments" id="comments" rows="7" className="form-control" placeholder="Please state as much information as possible with regards to booking enquiries including date, venue, budget, number of people etc." onChange={handleChange}></textarea>
                                                    {errors.comments && <p className="error">{errors.comments}</p>}
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="formbtns">
                                                    <p>After you contact us via this form, we will aim to respond to your enquiry within 48hrs.</p>
                                                    <button type="submit">Send</button>
                                                    {errorMessage && <div className="successmessage"><p className="error">{errorMessage}</p></div>}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    {loading && (
                                        <div className="formloader">
                                            <div className="spinner"></div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15955.062841999306!2d103.81676368715817!3d1.316146800000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19ff56bee347%3A0xdb869a9b11791107!2sDance%20Embassy!5e0!3m2!1sen!2sin!4v1725985529150!5m2!1sen!2sin" title="Google Map" width="100%" height="600" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <Footer/>
        </React.Fragment>
    )
}