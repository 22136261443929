import React, { useState } from "react";
import { FeaturedLists } from "../../Api/clients";
import Sliders from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Ourclient/ourclient.css";
import MagicSliderDots from 'react-magic-slider-dots';
import 'react-magic-slider-dots/dist/magic-dots.css';
import { Link } from "react-router-dom";

const featuredsetting = {
  dots: true,
  infinite: true,
  speed: 200,
  autoplay: true,
  autoplaySpeed: 10000,
  slidesToShow: 5,
  slidesToScroll: 1,
  pauseOnHover: false,
  arrows: false,
  appendDots: dots => {
    return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={30} />;
  },
  responsive: [
    {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
        },
    },
    {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
    },
  ]
};

export default function FeaturdIn() {
  const [Clientlist, setClients] = useState([]);
  return (
    <React.Fragment>
      <FeaturedLists onFetch={setClients} />
      <section className="ourclients nobackground">
          <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                      <div className="clientheaidng">
                          <h2>Recently Featured In</h2>
                      </div>
                  </div>
              </div>
          </div>
          <div className="container">
              <div className="clientsslider">
                  <Sliders {...featuredsetting}>
                      {Clientlist.map((featured) => (
                          <div className="clientimgbox" key={featured.id}>
                              <Link to={featured.link} target="_blank">
                                <div className="clientimgs">
                                  <img src={featured.logo} alt={featured.name} />
                                </div>
                              </Link>
                          </div>
                      ))}
                  </Sliders>
              </div>
          </div>
      </section>
    </React.Fragment>
  );
};