import React from "react";
import Sliders from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./review.css";
import { Link } from "react-router-dom";
import { ReactComponent as GoogleIcon } from "../../assets/images/google.svg";
import { FaStar } from "react-icons/fa";
import { Testimonialsdata } from "./reviewdata";
import MagicSliderDots from 'react-magic-slider-dots';
import 'react-magic-slider-dots/dist/magic-dots.css';

const testisetting = {
  dots: true,
  infinite: true,
  speed: 200,
  autoplay: true,
  autoplaySpeed: 10000,
  slidesToShow: 4,
  slidesToScroll: 1,
  pauseOnHover: false,
  arrows: true,
  appendDots: dots => {
    return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={30} />;
  },
  responsive: [
    {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
    },
    {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
    },
  ]
};

export default function Review() {
  return (
    <section className="testimonials">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="testimonailheaidng">
                        <h2>What Our Members Say</h2>
                    </div>
                </div>
            </div>
            <div className="reviewhead">
                <div className="reviewheadleft">
                    <div className="googlereviews">
                        <GoogleIcon /><span>Reviews</span>
                    </div>
                    <div className="googleratings">
                        <div className="howrat">
                            <span>5.0</span>
                        </div>
                        <div className="ratingstars">
                            {[...Array(5)].map((_, index) => (
                                <FaStar key={index} />
                            ))}
                        </div>
                        <div className="totalreveiwcount">
                            <p>(66)</p>
                        </div>
                    </div>
                </div>
                <div className="reviewheadright">
                    <div className="testimonailbtns">
                        <Link to="https://www.google.com/search?q=dance+embassy+singapore&oq=dance+e&gs_lcrp=EgZjaHJvbWUqDggBEEUYJxg7GIAEGIoFMgYIABBFGDkyDggBEEUYJxg7GIAEGIoFMg4IAhBFGCcYOxiABBiKBTIKCAMQABixAxiABDIKCAQQLhixAxiABDIGCAUQRRg8MgYIBhBFGDwyBggHEEUYPNIBCDM0ODRqMWo3qAIAsAIA&sourceid=chrome&ie=UTF-8&lqi=ChdkYW5jZSBlbWJhc3N5IHNpbmdhcG9yZUi64unqnbCAgAhaIxAAEAEYABgBGAIiF2RhbmNlIGVtYmFzc3kgc2luZ2Fwb3JlkgENZGFuY2VfY29tcGFueZoBJENoZERTVWhOTUc5blMwVkpRMEZuU1VSQ05YUkxOeTFuUlJBQqoBUxABKhEiDWRhbmNlIGVtYmFzc3koADIfEAEiG1NyqohmPVEo5-rwn-Y9CNciHv0tE2oWKuYJazIbEAIiF2RhbmNlIGVtYmFzc3kgc2luZ2Fwb3Jl#lkt=LocalPoiReviews&lrd=0x31da19ff56bee347:0xdb869a9b11791107,3,,,,&rlimm=15818500731989201159" target="_blank">Review us on Google</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="reviewslider">
                <Sliders {...testisetting}>
                    {Testimonialsdata.map((reviewdata) => (
                        <div className="tesimonialbox" key={reviewdata.id}>
                            <div className="testiboxhead">
                                <div className="testimonialimg">
                                    <img src={reviewdata.author_img} alt={reviewdata.author_name} referrerpolicy="no-referrer"/>
                                </div>
                                <div className="testimonialname">
                                    <p>{reviewdata.author_name}</p>
                                </div>
                            </div>
                            <div className="testimonialstarbox">
                                {[...Array(5)].map((_, index) => (
                                    <FaStar key={index} />
                                ))}
                            </div>
                            <div className="testimonailcontent">
                                <p>{reviewdata.testimonial_content}</p>
                            </div>
                        </div>
                    ))}
                </Sliders>
            </div>
        </div>
    </section>
  );
};