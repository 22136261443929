export const Testimonialsdata = [
    {
        id: '1',
        author_name: 'Abigail V Vasantha',
        author_img: 'https://lh3.googleusercontent.com/a-/ALV-UjWzSkRGEox6dqtEFyer3l0wB11wzJ5HUyi3tzfiIRHCM5OqgvA=s64-c-rp-mo-br100',
        testimonial_content: "Rosie is a warm person and very Friendly . Her class was super fun and enjoyable"
    },
    {
        id: '2',
        author_name: 'Rajul Mehta',
        author_img: 'https://lh3.googleusercontent.com/a/ACg8ocK7xQm0xZe3V2AEIQXmBOuffgWScewQR-8hTdxeZcIEWeFXdQ=s64-c-rp-mo-br100',
        testimonial_content: "Lavania brought an incredible energy to the experience, making it an absolute blast with friends! The moves challenged us to step out of our comfort zones, fostering a sense of bonding and camaraderie. We ended up having an amazing time, and the shared experience brought us even closer together"
    },
    {
        id: '3',
        author_name: 'Lakshmi Ishwar',
        author_img: 'https://lh3.googleusercontent.com/a/ACg8ocKFqZab0otFb0mU8EWsqpcjIVCJXZ17eUymB30hD6WzNmiJeA=s64-c-rp-mo-br100',
        testimonial_content: "What a fun afternoon of dance and Bollywood music with instructor Lavania. We had a fantastic time"
    },
    {
        id: '4',
        author_name: 'Malvika Rai',
        author_img: 'https://lh3.googleusercontent.com/a/ACg8ocJTVtdsIvmpM-9kfQSyx4n96xiXttlCYUaMG9y-KaGagjOlGg=s64-c-rp-mo-br100',
        testimonial_content: "it was an amazing experience at the dance centre. our facilator Lavania was so good that we learnt the two dances and enjoyed every minute."
    },
    {
        id: '5',
        author_name: 'Leonna Ong',
        author_img: 'https://lh3.googleusercontent.com/a/ACg8ocLd9Jkt1FNEwofa7NBb5IOKMpf4E3E3qB_IUlBdAAqKwdrj7A=s64-c-rp-mo-br100',
        testimonial_content: "I had a group of University students from America who have had no dance experience. The class was PHENOMENAL for them everyone enjoyed it. Instructor Farzana and Rosie were amazing!!!"
    },
    {
        id: '6',
        author_name: 'ANCILINE JOSE',
        author_img: 'https://lh3.googleusercontent.com/a-/ALV-UjUHb6dMJYOq3LU7_Tv4sXVz4I7ZPz2j4KT88wHRXIEB9evs_eJ6=s64-c-rp-mo-br100',
        testimonial_content: "I recently attended a Zumba session, and it was an incredible experience! The atmosphere was very welcoming, making it easy for newcomers to feel comfortable right away. The session itself was energetic and power-packed, with high-energy music and engaging routines that kept everyone moving and motivated. After the session, I was super hungry, so it's a good idea to have some light snacks ready because you are going to sweat a lot here. Overall, it was a fantastic workout that left me feeling invigorated and ready for more. Highly recommended!"
    },
    {
        id: '7',
        author_name: 'Nishtha Navandar',
        author_img: 'https://lh3.googleusercontent.com/a-/ALV-UjU3V8ME217hMjgOFi9AWGgczhkhF7qzwXvq5sgb9Qq4eSJnEtZxbQ=s64-c-rp-mo-br100',
        testimonial_content: "Thank you Lavania for helping with the most awesome dance party for kids birthday!! Kids were fully engaged and had a fantastic time dancing to Taylor Swift tunes!! Thank you once again."
    },
    {
        id: '8',
        author_name: 'Darshini .A',
        author_img: 'https://lh3.googleusercontent.com/a/ACg8ocJkCLNyuJMu5ds4Cw0NUI2G4GTIk_IjkUtIxMUs9gSwVNqpUQ=s64-c-rp-mo-br100',
        testimonial_content: "It was an amazing experience having her over for a fun filled dance night. She did a great job encouraging everyone to participate and it was truely a memorable night for us. Thank you Lavania! ☺️"
    }
]