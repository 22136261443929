import React, { useEffect, useState } from "react";
import './navbar.css';
import { Link } from "react-router-dom";
import MainLogo from "../../assets/images/mainlogo.svg";
import { FaBarsStaggered } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import {WebsiteSettings} from "../../Api/pagedata";

export default function Navbar() {
    useEffect(() => {
        const header = document.querySelector('.manuareas');
        const sticky = header.offsetTop;
        const handleScroll = () => {
            if (window.scrollY > sticky) {
                header.classList.add('sticky');
            } else {
                header.classList.remove('sticky');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const toggleSidebar = () => {
        setIsSidebarOpen(prev => {
            const newState = !prev;
            document.body.classList.toggle('no-scroll1', newState);
            return newState;
        });
    };
    const toggleSidebarClose = () => {
        setIsSidebarOpen(prev => {
            document.body.classList.remove('no-scroll1');
            return false;
        });
    };
    const toggleDropdown = (dropdown) => {
        setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
    };
    const [Websitedata, setWebsiteSetting] = useState([]);
    return (
        <React.Fragment>
        <WebsiteSettings onFetch={setWebsiteSetting} />
        <section className="manuarea">
            <div className="topbararea">
                <div className="container">
                    <div className="topbar">
                        <div className="row">
                            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 col-12">
                                <p>7 Day Trial for just $20. Access to all 3 studio locations. <Link to="https://momence.com/Dance-Embassy/membership/First-Timer-7-Day-Trial-Pass/25312" target="_blank">Click Here to Purchase</Link></p>
                            </div>
                            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 col-12">
                                <ul>
                                    <li><a href={'mailto:'+Websitedata.email_id}><i className="fa-regular fa-envelope"></i> {Websitedata.email_id}</a></li>
                                    <li><a href={'tel:'+Websitedata.phone_number}><i className="fa-solid fa-phone-volume"></i> {Websitedata.phone_number}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="manuareas">
            <div className="container">
                <div className="menuarea">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-8 col-sm-9 col-xs-9 col-9">
                            <div className="row align-items-center">
                                <div className="logoarea">
                                    <Link to="/"><img src={MainLogo} alt="Main Logo" width="100" height="200" /></Link>
                                </div>
                                <div className="otherheadernew">
                                    <Link to="/">
                                        <h2>Dance <span>Embassy</span></h2>
                                        <p>Your Happy Place</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-3 col-xs-3 col-3">
                            <div className="mobilemenu">
                                <button type="button" className="mobiletoggle" onClick={toggleSidebar}><FaBarsStaggered/></button>
                            </div>
                            <div className="mainmenu desktopmenu">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li className="has-menu"><Link to="/our-story/">About Us</Link>
                                        <ul className="sub-menu">
                                            <li><Link to="/our-story/">Our Story</Link></li>
                                            <li><Link to="/faq/">FAQ</Link></li>
                                            <li><Link to="/blog/">Blog</Link></li>
                                            <li><Link to="https://www.instagram.com/danceembassy/?hl=en" target="_blank">Join Our Community</Link></li>
                                        </ul>
                                    </li>
                                    <li className="has-menu"><Link to="/zumba-classes/">Classes</Link>
                                        <ul className="sub-menu">
                                            {/* <li><Link to="/zumba-classes/">Zumba Classes</Link></li>
                                            <li><Link to="/bollywood-for-kids/">Bollywood for kids</Link></li> */}
                                            <li><Link to="/studio-locations/">Studio Locations</Link></li>
                                            <li><Link to="/class-schedule/">Class Schedule</Link></li>
                                            <li><Link to="https://momence.com/u/danceembassy" target="_blank">Book Class</Link></li>
                                            <li><Link to="/pricing/">Pricing</Link></li>
                                            <li><Link to="https://bollyfreak.gumroad.com/" target="_blank">On Demand Videos</Link></li>
                                        </ul>
                                    </li>
                                    <li className="has-menu"><Link to="#">Corporates</Link>
                                        <ul className="sub-menu">
                                            <li><Link to="/team-building-workshops/">Team Building</Link></li>
                                            <li><Link to="/retreats/">Retreats</Link></li>
                                            <li><Link to="/employee-wellness/">Employee Wellness</Link></li>
                                        </ul>
                                    </li>
                                    <li className="has-menu"><Link to="#">Private Parties</Link>
                                        <ul className="sub-menu">
                                            <li><Link to="/kids-dance-classes-and-parties/">Kids Dance Classes and Parties</Link></li>
                                            <li><Link to="/wedding-dance/">Wedding Dance</Link></li>
                                            <li><Link to="/private-parties-and-lessons/">Private Parties and lessons</Link></li>
                                            {/* <li><Link to="/private-parties-and-lessons/">School Workshops</Link></li> */}
                                        </ul>
                                    </li>
                                    <li><Link to="/contact-us/">Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className={`menuleftslide sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
        {isSidebarOpen && (
            <div className="mobilemenuarea">
                <button class="th-menu-toggle close_menu" onClick={toggleSidebarClose}><FaTimes/></button>
                <div className="mobilemenulogoarea">
                    <Link to="/"><img src={MainLogo} alt="Main Logo" /></Link>
                </div>
                <div className="mobilemenuitems">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li className={`has-submenu ${activeDropdown === 'aboutus' ? 'active' : ''}`}>
                            <Link to="#" onClick={() => toggleDropdown('aboutus')}>About Us <span class="th-mean-expand"></span></Link>
                            {activeDropdown === 'aboutus' && (
                                <ul className="menusub-menu">
                                   <li><Link to="/our-story/">Our Story</Link></li>
                                    <li><Link to="/faq/">FAQ</Link></li>
                                    <li><Link to="/blog/">Blog</Link></li>
                                    <li><Link to="https://www.instagram.com/danceembassy/?hl=en" target="_blank">Join Our Community</Link></li>
                                </ul>
                            )}
                        </li>
                        <li className={`has-submenu ${activeDropdown === 'classes' ? 'active' : ''}`}>
                            <Link to="#" onClick={() => toggleDropdown('classes')}>Classes <span class="th-mean-expand"></span></Link>
                            {activeDropdown === 'classes' && (
                                <ul className="menusub-menu">
                                    {/* <li><Link to="/zumba-classes/">Zumba Classes</Link></li>
                                    <li><Link to="/bollywood-for-kids/">Bollywood for kids</Link></li> */}
                                    <li><Link to="/studio-locations/">Studio Locations</Link></li>
                                    <li><Link to="/class-schedule/">Class Schedule</Link></li>
                                    <li><Link to="https://momence.com/u/danceembassy" target="_blank">Book Class</Link></li>
                                    <li><Link to="/pricing/">Pricing</Link></li>
                                    <li><Link to="https://bollyfreak.gumroad.com/" target="_blank">On Demand Videos</Link></li>
                                </ul>
                            )}
                        </li>
                        <li className={`has-submenu ${activeDropdown === 'corporates' ? 'active' : ''}`}>
                            <Link to="#" onClick={() => toggleDropdown('corporates')}>Corporates <span class="th-mean-expand"></span></Link>
                            {activeDropdown === 'corporates' && (
                                <ul className="menusub-menu">
                                    <li><Link to="/team-building-workshops/">Team Building</Link></li>
                                    <li><Link to="/retreats/">Retreats</Link></li>
                                    <li><Link to="/employee-wellness/">Employee Wellness</Link></li>
                                </ul>
                            )}
                        </li>
                        <li className={`has-submenu ${activeDropdown === 'privateparties' ? 'active' : ''}`}>
                            <Link to="#" onClick={() => toggleDropdown('privateparties')}>Private Parties <span class="th-mean-expand"></span></Link>
                            {activeDropdown === 'privateparties' && (
                                <ul className="menusub-menu">
                                    <li><Link to="/kids-dance-classes-and-parties/">Kids Dance Classes and Parties</Link></li>
                                    <li><Link to="/wedding-dance/">Wedding Dance</Link></li>
                                    <li><Link to="/private-parties-and-lessons/">Private Parties and lessons</Link></li>
                                    {/* <li><Link to="/private-parties-and-lessons/">School Workshops</Link></li> */}
                                    <li><Link to="/team-building-workshops/">Team Building Workshops</Link></li>
                                </ul>
                            )}
                        </li>
                        <li><Link to="/contact-us/">Contact Us</Link></li>
                    </ul>
                </div>
            </div>
        )}
        </div>
        </React.Fragment>
    );
}